import React, { useEffect, useState } from "react";
import $ from 'jquery';
import 'slick-carousel/slick/slick.scss';
import { Helmet } from "react-helmet";
import { load } from 'recaptcha-v3';

import Layout from "../components/layout";
import SEO from "../components/seo";

if (typeof window !== `undefined`) {
  $.slick = require('slick-carousel/slick/slick');
  const smoothScroll = require('smooth-scroll');
  if(window.innerWidth > 992) {
    smoothScroll('a[href*="#"]', { offset: 83 })
  } else {
    smoothScroll('a[href*="#"]', { offset: 71 })
  }
}

export const query = graphql`
  {
    wpPage(lang: { eq: "pl" }, pageTemplate: { eq: "page_homepage.php" } ) {
      title
      content
      databaseId
    }
  }
`;

const IndexPage = function({ data }) {
  const [sending, setSending] = useState(false);
  useEffect(() => {
    request();
    if(typeof $.slick !== `undefined`) {
      $('.our-work-gallery').each(function() { $(this).find('.slick-slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        appendArrows: $(`#${this.id} .section-append`),
        appendDots: $(`#${this.id} .slider-nav`),
        prevArrow: $(`#${this.id} .slick-prev`),
        nextArrow: $(`#${this.id} .slick-next`),
        dots: true,
        mobileFirst: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              draggable: false,
              swipe: false,
              slidesPerRow: 3,
              rows: 2,
            }
          }
        ]
      })});
    }
    $(`.front-page-section#our-work .slick-slide a[href^="${process.env.GATSBY_MEDIA_URL}"]`).colorbox({
      scrolling: false,
      arrowKey: false,
      maxWidth: '95%',
      maxHeight: '95%'
    });

    $('.contact-form').unbind('submit');
    $('.contact-form').submit(function(e) {
      if(!sending) {
        setSending(true);
        load('6Lcj_b8ZAAAAALEduGoJZi4culyll6O_g7WNAIjr').then(recaptcha => {
          recaptcha.execute('submit').then(token => {
            const formData = new FormData();
            formData.append('your-name', $('.contact-form').find('[name="name"]').val());
            formData.append('your-email', $('.contact-form').find('[name="email"]').val());
            formData.append('your-message', $('.contact-form').find('[name="message"]').val());
            formData.append('_wpcf7_recaptcha_response', token);
            fetch(`${process.env.GATSBY_API_URL}/wp-json/contact-form-7/v1/contact-forms/316/feedback`, {
              method: 'POST',
              body: formData
            }).then(data => data.json()).then(data => {
              if (data.status === 'mail_sent') {
                $('.contact-form')[0].reset();
                $('.contact-form').find('.alert').css('display', 'none');
                $('.contact-form').find('.alert-success').css('display', 'block');
              }
              else {
                $('.contact-form').find('.alert').css('display', 'none');
                $('.contact-form').find('.alert-danger').css('display', 'block');
              }
              setSending(false);
            });
          });
        });
        return false;
      } else {
        return false;
      }
    });
  });

  const [head, setHead] = useState({});
  const request = async () => {
    const response = await fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages/${data.wpPage.databaseId}`);
    const json = await response.json();
    setHead({ yoast_title: json.yoast_title, yoast_meta: json.yoast_meta });
  };

  String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };

  const content = data.wpPage.content.replaceAll(process.env.GATSBY_API_IMAGES_PATH, process.env.GATSBY_STATIC_IMAGES_PATH);

  return (
    <Layout lang="pl">
      <SEO lang="pl" title="GSoftware" canonical={process.env.GATSBY_WEBSITE_URL} />
      { head && <Helmet>
        { head.yoast_meta && head.yoast_meta.map(meta_value => {
            meta_value.content = meta_value.content.replaceAll(process.env.GATSBY_API_URL, process.env.GATSBY_WEBSITE_URL);
            if (meta_value.property === 'og:title') {
              return (
                <title>{meta_value.content}</title>
              )
            }
            return (
                <meta
                    name={meta_value.name || meta_value.property}
                    content={meta_value.content}
                />
            );
        })}
      </Helmet> }
      { data && <div dangerouslySetInnerHTML={{ __html: content }}></div> }
    </Layout>
  );
}

export default IndexPage;
